import React from "react";

import Image from "./Image";
import LinkButtonInternal from "../components/LinkButtonInternal";

const FullCard = ({
  linkLabel,
  linkDestination,
  cardImage,
  cardTitle,
  cardDescription,
  cardSubheading,
  lastItem,
  className,
}) => {
  let fullImage;
  let nonFullImage;
  if (cardImage) {
    if (cardImage.node.sourceUrlSharp) {
      fullImage = true;
    } else {
      nonFullImage = true;
    }
  } else {
    fullImage = false;
    nonFullImage = false;
  }
  return (
    <div
      className={`relative sm:max-w-[550px] lg:max-w-[75%] z-20 flex flex-wrap lg:justify-between px-12 md:px-0 md:border-cerulean md:border-b-2 md:pb-12 space-y-4 lg:space-y-0 mt-12 ${className}`}
    >
      <div className="lg:max-w-[40%] h-auto">
        {fullImage && (
          <Image
            image={cardImage.node}
            alt={cardImage.node.altText}
            className={`w-full object-cover lg:mb-0`}
          />
        )}
        {nonFullImage && (
          <img
            alt={cardImage.node.altText}
            src={cardImage.node.sourceUrl}
            srcSet={cardImage.node.srcSet}
          />
        )}
      </div>
      <div
        className={`max-w-sm lg:max-w-[50%] space-y-4 pb-12 mb-12 md:mb-0 md:pb-0 ${
          lastItem ? "" : "border-b-2"
        } border-cerulean md:border-b-0`}
      >
        <p className="text-darkblue text-xl font-bold">{cardTitle}</p>
        <div
          className="text-darkblue"
          dangerouslySetInnerHTML={{ __html: cardDescription }}
        ></div>
        {cardSubheading && (
          <p className="text-cerulean text-xl font-bold">{cardSubheading}</p>
        )}
        {linkDestination && (
          <LinkButtonInternal
            color="blue"
            label={linkLabel}
            link={linkDestination}
          />
        )}
      </div>
    </div>
  );
};

export default FullCard;
