import React, { useState } from "react";
import { graphql } from "gatsby";

import { BsArrowRight } from "react-icons/bs";
import { server } from "../../lib/server";
import Layout from "../components/Layout";
import FullCard from "../components/FullCard";
import SidePanel from "../components/SidePanel";

export const pageQuery = graphql`
  query getNewsPage {
    wp {
      posts(first: 3, where: { orderby: { field: DATE, order: DESC } }) {
        edges {
          cursor
          node {
            id
            title
            uri
            excerpt
            featuredImage {
              node {
                ...MediaItem
              }
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
      page(id: "/news/", idType: URI) {
        id
        title
      }
    }
  }
`;

const News = ({ data }) => {
  const page = data.wp.page;
  const posts = data.wp.posts.edges;
  const [postsData, setPostsData] = useState(posts);
  const [lastPost, setLastPost] = useState(posts[posts.length - 1].cursor);
  const [isEnd, setIsEnd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadMorePosts = async () => {
    setIsLoading(true);
    const response = await fetch(server, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
          query getMorePosts($first: Int , $after: String!) {
            posts(first: $first, where: {orderby: { field: DATE, order: DESC }}, after: $after) {
              edges {
                cursor
                node {
                  id
                  title
                  uri
                  excerpt
                  featuredImage {
                    node {
                      srcSet
                      sourceUrl
                      altText
                      id
                      mimeType            
                    }
                  }
                }
              }
              pageInfo {
                hasNextPage
                hasPreviousPage
              }
            }
          }
        `,
        variables: {
          first: 3,
          after: `${lastPost}`,
        },
      }),
    });
    const results = await response.json();
    const resultsData = results.data.posts;
    const resultsPosts = resultsData.edges;

    setPostsData([...postsData, ...resultsPosts]);
    setIsLoading(false);
    if (resultsData.pageInfo.hasNextPage) {
      setLastPost(resultsPosts[resultsPosts.length - 1].cursor);
    } else {
      setIsEnd(true);
    }
  };

  return (
    <Layout title={page.title}>
      <div className="relative 2xl:container">
        <SidePanel title={page.title} />
        <div className="flex flex-col items-center lg:items-end lg:space-y-16 lg:p-36 -mt-36 lg:mt-0">
          {postsData.map((item, i, array) => {
            let lastItem = false;
            if (array.length - 1 === i) {
              lastItem = true;
            }
            const post = item.node;
            return (
              <FullCard
                key={post.id}
                linkLabel="Read more"
                linkDestination={`/news` + post.uri}
                cardData={post}
                cardImage={post.featuredImage}
                cardTitle={post.title}
                cardDescription={post.excerpt}
                lastItem={lastItem}
                className=""
              />
            );
          })}
          {!isEnd && !isLoading && (
            <button
              className={`flex border-2 overflow-hidden relative rounded-2xl mb-12 lg:mb-0 transition duration-200 px-4 py-1 text-sm justify-between items-center max-w-[200px] group text-cerulean border-cerulean hover:text-white`}
              onClick={(e) => {
                loadMorePosts();
              }}
            >
              <span
                className={`link-bg absolute transition-all duration-300 -left-full group-hover:left-0 h-full w-full bg-cerulean`}
              ></span>
              <span className="z-10 transition duration-200 flex justify-between w-full">
                Load more
                <BsArrowRight className="text-xl" />
              </span>
            </button>
          )}
          {!isEnd && isLoading && (
            <div className="text-center flex border-2 overflow-hidden relative rounded-2xl transition duration-200 px-4 py-1 text-sm justify-between items-center max-w-[200px] btn bg-cerulean border-cerulean text-white">
              Loading...
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default News;
